import React, { useEffect, useState } from 'react'
import {DualEnrolment} from '@mediassistrepo/maven-old';
import { useAppSelector } from '../app/hooks';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { Iframe } from './SingleEnrollment';
import Loader from './shared/Loader';
import { getConfig } from '../reducers/config.slice';
import { CustomizeEnrolment } from '../interfaces/config.interface';
import { useNavigate } from 'react-router';
import FooterWeb from './shared/Footer';
import HeaderWeb from './shared/Header';

export class DualEnrolmentOption {
      id: number;
      title: string;
      description: string;
      handleClick: Function
      enrolStr: string;
}

const DualEnrollmentSelectionScreen = () => {
  const [enrolStr, setEnrolStr] = useState<string>('');
  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);
  const [isLoading,setIsLoading] = useState(false);
  const [showEnrolOptions,setShowEnrolOptions]  = useState(true);
  const [dualEnrollmentOptions,setDualEnrollmentOptions] = useState(null);
  const config = useAppSelector(getConfig);
  const handleSelect = (enrolmentDetails:DualEnrolmentOption) => {
    const enrolmentSlug = encodeURIComponent(enrolmentDetails?.title?.toLowerCase()?.split(" ")?.join("-"));
    const data = { enrolStr: enrolmentDetails?.enrolStr  };
    navigate(`/enrolments/${enrolmentSlug}`,{ state: data });
  }
  
  useEffect(() => {
    if(config?.customizeEnrolment){
        const customizeEnrollment = config?.customizeEnrolment;
        const dualEnrolOptions = Object.keys(customizeEnrollment.Enrolments).map((enrolment:any,index: number) => {
            const enrolOption = new DualEnrolmentOption();
            enrolOption.description = enrolment;
            enrolOption.enrolStr = customizeEnrollment.Enrolments[enrolment];
            enrolOption.title = enrolment;
            enrolOption.id = index+1;
            enrolOption.handleClick = handleSelect;
            return enrolOption;
        });
        setDualEnrollmentOptions(dualEnrolOptions);
    }
  },[config]);

  return (
    <div>
        {isLoading && <Loader/>}
      <HeaderWeb onBackBtnClick={() => navigate("/")} isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Enrolment"} />
      <div className="has-sticky-header has-sticky-footer">
        {showEnrolOptions && <DualEnrolment dualEnrolOptions={dualEnrollmentOptions} />}
      </div>
         {/* <div className="has-sticky-header has-sticky-footer">
        {enrolStr && <Iframe src={`https://stg-enrol.mediassist.in/logon.aspx?usr_id=39702815&corp_Id=5035`} title="Enrollment" onLoad={handleStopLoading} ></Iframe>}
        </div> */}
    </div>
  )
}

export default DualEnrollmentSelectionScreen