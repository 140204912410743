import { getCookieValue } from "../common/helpers";

export const dataLayerPush = (event:any, payload:any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...payload,
      platform: getCookieValue('source') == 'native' ? 'app':'web',
      maid: localStorage.getItem('maid') || "NA",
    });
  };