import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { dataLayerPush } from "../utils/gtm";
import ErrorComponent from "./Error";
import { useAppSelector } from "../app/hooks";
import { getBaseProfile } from "../reducers/baseProfile.slice";

const ClaimDetailsComponent = () => {
  const { param } = useParams();
  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);

  const isPriBenfMaId = `${baseProfile?.data?.maid}`;

  useEffect(() => {
    const component: any = document.querySelector(
      "claim-details-web-component"
    );

    const handleGoBack = () => navigate(-1);
    const handleGoHome = () => navigate("/");

    if (component) {
      component.addEventListener("gtm-events", handleGtmEvents);
      component.addEventListener("GO_BACK", handleGoBack);
      component.addEventListener("GO_HOME", handleGoHome);
    }
    return () => {
      if (component) {
        component.removeEventListener("gtm-events", handleGtmEvents);
        component.removeEventListener("GO_BACK", handleGoBack);
        component.removeEventListener("GO_HOME", handleGoHome);
      }
    };
  }, [navigate]);

  const handleGtmEvents = (e: any) => {
    e?.detail && dataLayerPush?.(e?.detail[0], e?.detail[1]);
  };

  return (
    <div style={{padding:20}}>
      {param ? (
        <claim-details-web-component
          claimId={encodeURIComponent(param)}
          isPriBenfMaId={isPriBenfMaId}
        ></claim-details-web-component>
      ) : (
        <ErrorComponent error="pageNotFound" />
      )}
    </div>
  );
};
export default ClaimDetailsComponent;
