import React, { useEffect, useState } from 'react'
import { Iframe } from './SingleEnrollment';
import Loader from './shared/Loader';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { enrollmentAsync, getEnrollment } from '../reducers/enrollment.slice';



const DualEnrollment = () => {

  const location = useLocation();
  const [enrolStr, setEnrolStr] = useState('');
  const baseProfile = useAppSelector(getBaseProfile);
  const [loading,setLoading] = useState(true);
  const enrollmentDetails = useAppSelector(getEnrollment);
  const dispatch = useAppDispatch();

 const handleStopLoading = () => {
    setLoading(false);
 }

   useEffect(() => {
       if (!enrollmentDetails)
         dispatch(enrollmentAsync());
   }, []);

  useEffect(() => {
    if(enrollmentDetails?.data){
      const enrolStrWithProps = location?.state?.enrolStr?.replace('[user]',enrollmentDetails?.data);
      setEnrolStr(enrolStrWithProps);
    }
  },[enrollmentDetails]);
   
  
  return (
    <>
    {loading && <Loader />}
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Enrolment"} />
         <div className="has-sticky-header has-sticky-footer">
        {enrolStr && <Iframe src={enrolStr} title="Enrollment" onLoad={handleStopLoading} ></Iframe>}
        </div>
    </>
  )
}

export default DualEnrollment