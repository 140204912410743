import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getBeneficiaries, getBeneficiariesLoading } from '../reducers/beneficiaries.slice';
import { enrollmentAsync, getEnrollment } from '../reducers/enrollment.slice';
import FooterWeb from './shared/Footer';
import HeaderWeb from './shared/Header';
import Loader from './shared/Loader';
import { getConfig, isConfigLoading } from '../reducers/config.slice';
import DualEnrollment from './DualEnrollment';
import SingleEnrollment from './SingleEnrollment';
import { useNavigate } from 'react-router';

export const Iframe = styled.iframe.attrs(() => {
  return {
    className: 'w-full',
  };
})`
    min-height: 100vh;
  `;

const Enrollment = () => {
  const beneficiariesResponse = useAppSelector(getBeneficiaries)
  const [isLoading, setIsLoading] = useState(true);
  const config = useAppSelector(getConfig);
  const configLoad = useAppSelector(isConfigLoading);
  const benefLoad = useAppSelector(getBeneficiariesLoading)
  const navigate = useNavigate();


  useEffect(() => {
    if(!isLoading){
      setIsLoading(true);
    }
    const customizeEnrolment = config?.customizeEnrolment;
    if (customizeEnrolment && beneficiariesResponse && beneficiariesResponse?.beneficiaryDetails?.length > 0) {
      const doubleEnrolmentStartDate = new Date(customizeEnrolment?.DoubleEnrolmentStartDate);
      const empDoj = new Date(beneficiariesResponse?.beneficiaryDetails[0].priBenefDoj);
      const dualEnrolmentEndDate = new Date(doubleEnrolmentStartDate.getTime() + (customizeEnrolment.cutOffDays * 24 * 60 * 60 * 1000));


      if (customizeEnrolment.ShowDoubleEnrolment && customizeEnrolment.cutOffDays > 0 &&
          empDoj >= doubleEnrolmentStartDate && new Date() <= dualEnrolmentEndDate) {
          navigate('/enrolments')
      }
    }
    if(!configLoad && !benefLoad){
      setIsLoading(false);
    }
  },[config,beneficiariesResponse])

  return (
    <>
      {isLoading && <Loader />}
      <HeaderWeb  isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Enrolment"} />
      {!isLoading && <SingleEnrollment/>}
      {/* <FooterWeb /> */}
    </>
  )
}

export default Enrollment;
